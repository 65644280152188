<template>
  <div class="container-fluid text-left">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Session Detail - {{ nc.ctx.patientName }}</h4>
      </div>
      <div class="card-body" >

        <div class="row">
          <div class="col-1">
            <button class="btn nw-btn-outline-info-sm" @click="prev()"><i class="fa fa-chevron-left"></i></button>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="col-1 text-right">Start:</div><div class="col-3 font-weight-bold">{{ startTime.toLocaleDateString()}} {{ startTime.toLocaleTimeString()}}</div>
              <div class="col-2 text-right">Threshold:</div><div class="col-1 font-weight-bold">{{ session.threshold}}</div>
              <div class="col-2 text-right">Duration:</div><div class="col-1 font-weight-bold">{{ session.duration}}</div>
            </div>
            <div class="row">
              <div class="col-1 text-right">End:</div><div class="col-3 font-weight-bold">{{ endTime.toLocaleDateString()}} {{ endTime.toLocaleTimeString()}}</div>
              <div class="col-2 text-right">Frequency:</div><div class="col-1 font-weight-bold">{{ session.frequency }}</div>
              <div class="col-2 text-right">Interventions:</div><div class="col-1 font-weight-bold">{{ session.interventions}}</div>
            </div>
            <div class="row">
              <div class="col-1 text-right"></div><div class="col-3 font-weight-bold"></div>
              <div class="col-2 text-right">Version:</div><div class="col-1 font-weight-bold">{{ session.version}}</div>
              <div class="col-2 text-right">Platform:</div><div class="col-1 font-weight-bold">{{ session.platform}}</div>
            </div>
          </div>
          <div class="col-1 text-right">
            <button class="ml-2 btn nw-btn-outline-info-sm" @click="next()"><i class="fa fa-chevron-right"></i></button>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-4 text-left">
            <select class="form-control" v-model="leftAxis" @change="fillData()">
              <option value="acc">Accelerometer</option>
              <option value="gyro">Gyroscope</option>
              <option value="hr">Heart Rate</option>
              <option value="str">Stress</option>
            </select>
          </div>

          <div class="col-4 offset-4 text-right">
            <select class="form-control" v-model="rightAxis" @change="fillData()">
              <option value="acc">Accelerometer</option>
              <option value="gyro">Gyroscope</option>
              <option value="hr">Heart Rate</option>
              <option value="str">Stress</option>
            </select>
          </div>
        </div>

        <session-chart :chart-data="datacollection" :options="options"></session-chart>

        <div class="progress ml-4 mr-4">
          <div class="progress-bar bg-light" role="progressbar" :style="lwidth" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          <div
            class="progress-bar bg-info"
            role="progressbar"
            :style="mwidth"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            @mousedown="doDown"
            @mouseup="doUp"
            @mousemove="doMove">
          </div>
          <div class="progress-bar bg-light" role="progressbar" :style="rwidth" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <!-- <div>{{ lwidth}} {{ mwidth}} {{ rwidth }}</div>
        <div>{{ bmin}} {{ bwidth}} {{ clickmin}}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import SessionChart from './SessionChart'
import NetClient from '@/util/netclient.js'

export default {
  components: { SessionChart},
  data () {
      return {
        isClick: false,
        clickmin: 0,
        x: 0,
        bmin: 0,
        bwidth: 100,
        lwidth: 'width: 0%',
        mwidth: 'width: 100%',
        rwidth: 'width: 0%',
        nc: NetClient,
        session: {},
        samples: [],
        startTime: new Date(),
        endTime: new Date(),
        leftAxis: 'str',
        rightAxis: 'str',
        datacollection: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'hour'
                }
            }],
            yAxes: [
              {
                type: 'linear',
                id: 'left-axis-id',
                position: 'left'
              },
              {
                type: 'linear',
                id: 'right-axis-id',
                position: 'right'
              }
            ]
        }
        }
      }
    },
    mounted () {
      this.loadData()
    },
    methods: {
      doDown (_evt) {
        this.x = _evt.x
        this.clickmin  = this.bmin
        this.isClick = true
      },
      doUp(_evt) {
        this.isClick = false
      },
      doMove(_evt) {
        if(this.isClick)
        {
          var diff = Math.floor(((_evt.x - this.x) * 100 / window.innerWidth) )
          console.log(_evt.x + ' ' + this.x + ' ' + diff)
          if(this.clickmin + diff < 0) this.bmin = 0
          else if(this.clickmin + this.bwidth + diff > 100) this.bmin = 100 - this.bwidth
          else this.bmin = this.clickmin + diff

          this.lwidth = 'width: ' + this.bmin + '%'
          this.mwidth = 'width: ' + this.bwidth + "%"
          this.rwidth = 'width: ' + (100 - this.bwidth - this.bmin) + "%"
        }
      },
      next () {
        NetClient.doRequest('rest/patient', { action: 'navigateSession', direction: 'next', sessionID: NetClient.sessionID}).then(msg => {
          if(typeof msg.data.session != 'undefined')
          {
            this.session = msg.data.session
            NetClient.sessionID = this.session.oid
          }
          if(typeof msg.data.samples != 'undefined') this.samples = msg.data.samples
          if('OK' == msg.retval) this.fillData()
        })
      },
      prev () {
        NetClient.doRequest('rest/patient', { action: 'navigateSession', direction: 'prev', sessionID: NetClient.sessionID}).then(msg => {
          if(typeof msg.data.session != 'undefined')
          {
            this.session = msg.data.session
            NetClient.sessionID = this.session.oid
          }
          if(typeof msg.data.samples != 'undefined') this.samples = msg.data.samples
          if('OK' == msg.retval) this.fillData()
        })
      },
      loadData () {
        NetClient.doRequest('rest/patient', { action: 'getSession', sessionID: NetClient.sessionID}).then(msg => {
          if(typeof msg.data.session != 'undefined') this.session = msg.data.session
          if(typeof msg.data.samples != 'undefined') this.samples = msg.data.samples
          if('OK' == msg.retval) this.fillData()
        })
      },
      fillData () {
        var ldata = []
        var idata = []
        var rdata = []
        var ii
        var ts
        var maxValue = -10000
        this.startTime = new Date(this.session.startTime)
        this.endTime = new Date(this.session.endTime)


        for(ii=0;ii<this.samples.length;ii++)
        {
          //console.log(this.samples[ii].ts)
          ts = new Date(this.samples[ii].ts)
          ldata.push({ t: ts, y: this.samples[ii][this.leftAxis]})
          rdata.push({ t: ts, y: this.samples[ii][this.rightAxis]})
          if(maxValue < this.samples[ii][this.leftAxis]) maxValue = this.samples[ii][this.leftAxis]
        }

        for(ii=0;ii<this.samples.length;ii++)
        {
          ts = new Date(this.samples[ii].ts)
          if(this.samples[ii].int > 0) idata.push({ t: ts, y: maxValue})
        }

        this.datacollection = {
          datasets: [
            {
              fill: false,
              radius: 2,
              borderColor: '#006072',
              label: this.leftAxis,
              data: ldata,
              yAxisID: 'left-axis-id'
            },
            {
              fill: false,
              radius: 8,
              pointStyle: 'triangle',
              showLine: false,
              borderColor: 'red',
              backgroundColor: 'red',
              label: 'Intervention',
              data: idata,
              yAxisID: 'left-axis-id'
            },
            {
              radius: 2,
              fill: false,
              borderColor: '#EBA200',
              label: this.rightAxis,
              data: rdata,
              yAxisID: 'right-axis-id'
            },
          ]
        }


      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blue-line {

}

</style>
